<template>
    <div>
        <b-modal id="popup-validar-senha"
                 ref="popup-validar-senha"
                 centered size="md"
                 hide-footer hide-header>

            <form>
                <b-form-group class="font-weight-bold py-2" label="Nova senha *" label-for="input-senha">
                    <b-form-input type="password" id="input-senha" v-model="senha" required></b-form-input>
                    <p v-if="validarSenha" class="erro-validacao-senhas">
                        Senha deve ser maior que 8 caracteres entre letras e numeros.
                    </p>
                </b-form-group>

                <b-form-group class="font-weight-bold py-2" label="Confirme a senha *" label-for="input-confirma-senha">
                    <b-form-input type="password" id="input-confirma-senha" v-model="confirmaSenha" required></b-form-input>
                    <p v-if="validarSenhasDiferentes" class="erro-validacao-senhas">
                        Senhas devem ser iguais
                    </p>
                </b-form-group>
            </form>

            <div class="w-100 py-2 text-center">
                <button class="btn-sm btn-secondary mr-1" @click="fecharPopup">CANCELAR</button>
                <button class="btn-sm btn-primary ml-1" @click="validarSenhas">SALVAR</button>
            </div>

            <div v-if="verificacaoMensagem" class="text-center mt-3">
                <p :class="verificacaoSucesso ? 'text-success' : 'text-danger'">{{verificacaoMensagem}}</p>
            </div>

        </b-modal>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'PopupValidarSenha',
        data() {
            return {
                senha: '',
                confirmaSenha: '',

                validarSenha: false,
                validarSenhasDiferentes: false,

                verificacaoSucesso: false,
                verificacaoMensagem: ''
            }
        },
        methods: {
            validarSenhas(e) {
                let senhaComNumero = (/\d+/g).test(this.senha);
                if (this.senha.length < 8 || !senhaComNumero) {
                    this.validarSenha = true;
                    return e.preventDefault();
                } else {
                    this.validarSenha = false
                }

                if (this.senha !== this.confirmaSenha) {
                    this.validarSenhasDiferentes = true;
                    return e.preventDefault();
                } else { this.validarSenhasDiferentes = false }

                this.alterarSenha();
            },
            alterarSenha() {
                let dadosAlterarSenha = {
                    "senhaNova": this.senha,
                    "confirmaSenha": this.confirmaSenha,
                    "id": this.urlQuery.usuarioId,
                    "token": this.urlQuery.codigo
                }

                axios.post('/api/autenticacao/senha/validar', dadosAlterarSenha)
                .then(res => {
                    this.verificacaoMensagem = res.data.Mensagem;

                    if (res.data.Sucesso) {
                        this.showToastSucesso()
                        this.verificacaoSucesso = true;
                        this.fecharPopup();
                    }
                    else { this.verificacaoSucesso = false; }
                })
                .catch(err => {
                    console.log(err);
                })
            },
            mostrarPopup() {
                this.limparForm();
                this.$refs['popup-validar-senha'].show();
            },
            fecharPopup() {
                this.limparForm();
                this.$nextTick(() => {
                    this.$bvModal.hide('popup-validar-senha')
                });
                this.$router.push('/login');
            },
            limparForm() {
                this.senha = '';
                this.confirmaSenha = '';
            },
            showToastSucesso() {
                this.$bvToast.toast('Senha recuperada com sucesso!', {
                    title: `Recuperação de senha`,
                    autoHideDelay: 3000,
                    variant: 'success',
                    solid: true
                });
            }
        },
        computed: {
            urlQuery: function () {
                return this.$route.query;
            }
        }
    }
</script>

<style scoped>
    .erro-validacao-senhas {
        color: red;
        font-size: 12px;
    }
</style>